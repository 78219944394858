<template>
	<v-menu
		ref="menu"
		v-model="menu"
		offset-y
		:nudge-right="20"
		:nudge-top="10"
		max-width="290"
		min-width="290"
		transition="scale-transition"
		:close-on-content-click="false"
	>
		<template #activator="{ on, attrs }">
			<v-text-field
				v-model="value"
				:label="label.toUpperCase()"
				:prepend-inner-icon="icon"
				readonly outlined color="primary"
				v-bind="attrs"
				v-on="on" hide-details="auto"
				:error-messages="getErrorMessage"
				@change="$emit('input', $event)"
			/>
		</template>
		<v-time-picker
			:value="value"
			format="24hr"
			scrollable
			@click:minute="save"
			@input="$emit('input', $event)"
		/>
	</v-menu>
</template>

<script>
import FormFieldError from "@/mixins/FormFieldError.js";

export default {
	name: "TimePicker",
	mixins: [FormFieldError],
	props: {
		value: {required: true},
		label: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		errors: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	data: () => ({
		menu: false,
	}),
	methods: {
		save() {
			this.$refs.menu.save()
		}
	}
}
</script>

<style scoped>

</style>
